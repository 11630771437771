
export default {
  name: "LossFormClaimInfo",
  props: {
    lossFormData: {
      type: Object,
      required: true
    }
  },
  methods: {
    getLabel(payOrInd: string): string {
      let label = "";
      const staticLabel = "MORTGAGE INFO.";
      const dynamicLabel = "| MORTGAGE BILLED";

      if (payOrInd === "1") {
        label = `${staticLabel} ${dynamicLabel}`;
      } else if (payOrInd === "0") {
        label = `${staticLabel} INSURE BILLED`;
      } else {
        label = staticLabel;
      }
      return label;
    }
  }
};
