
import Vue from "vue";
import { claimsMapActions, claimsMapState } from "@/store/modules/claims";
import * as selectOptions from "@/helpers/selectOptions";
import { authMapGetters } from "@/store/modules/auth";
import { get } from "lodash";
import { getLossMortgageAddress } from "@/helpers/lossFormDataProcessor";
import LossFormClaimInfo from "@/components/lossForm/LossFormClaimInfo.vue";
import DisplayData from "@/components/DataView/DisplayData.vue";
import { getPHPAPIFormattedDate } from "@/helpers/dateProcessor";
import {
  AtlasClaimRepresentativeOptions,
  FieldAdjusterOptions
} from "@/helpers/constants";
import { downloadClaimsDocument } from "@/helpers/downloadLossForm";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface IClaims {
  errorMessage: string;
  receivePaymentOption: string;
  hasValidCredentials: boolean;
  resourceNotFound: boolean;
  successMessage: string;
  isInsuredFieldsValid: boolean;
  isAgentFieldValid: boolean;
  isPolicyInfoValid: boolean;
  validationData: any;
  lossFormData: any;
  pageDataInitialized: boolean;
}
export default Vue.extend({
  name: "ViewLossForm",
  mixins: [ActivitiesMixin],
  components: {
    LossFormClaimInfo,
    DisplayData
  },
  data(): IClaims {
    return {
      receivePaymentOption: "",
      hasValidCredentials: false,
      resourceNotFound: false,
      errorMessage: "",
      successMessage: "",
      isInsuredFieldsValid: false,
      isAgentFieldValid: false,
      isPolicyInfoValid: false,
      validationData: {},
      pageDataInitialized: false,
      lossFormData: {}
    };
  },
  created() {
    this.initializePageData();
  },
  methods: {
    ...claimsMapActions(["getClaim"]),
    addHardCodedFields(): void {
      this.lossFormData.role = this.$getCurrentUser.role;
      const agentCode =
        this.lossFormData && this.lossFormData.formItem
          ? this.lossFormData.formItem.agentCode
          : get(
              this.lossFormData,
              "lossFormOtherInfo.Info.AgentCode"
            ).toUpperCase();
      this.lossFormData.agentCode = agentCode;

      // this.lossFormData.site = "ATLAS";
      this.lossFormData["policyNumber"] =
        get(this.lossFormData, "lossFormOtherInfo.Client.PolicyNum", "") || "";
      this.lossFormData["companyPrefix"] = this.lossFormData.policyNumber.split(
        " "
      )[0];
      this.lossFormData.isEditing = true;
    },
    async initializePageData() {
      try {
        await this.getClaim(this.claimId);
        const { data, ...rootFields } = this.editing as any;
        this.lossFormData = { ...rootFields, ...data };
        this.lossFormData.mortgageInfo = getLossMortgageAddress(
          this.lossFormData
        );
        this.addHardCodedFields();
        this.pageDataInitialized = true;
      } catch (error) {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      }
    },
    getYesOrNo(value: string): string {
      return value === "Y" ? "Yes" : "No";
    },
    getPaymentOption(value: string): string {
      return value === "I" ? "US Mail" : "E-Check/Email";
    },
    getFieldAdjuster(value: string): string {
      let option = FieldAdjusterOptions.find(option => option.value === value);
      return option ? option.label : "";
    },
    getAtlasClaimRepresentative(value: string): string {
      let option = AtlasClaimRepresentativeOptions.find(
        option => option.value === value
      );
      return option ? option.label : "";
    },
    getProcessedDate(key: string): Date | string {
      const dateAssigned = get(this.editing, key, "");
      let processedDateAssigned = "";
      if (dateAssigned) {
        processedDateAssigned = getPHPAPIFormattedDate(new Date(dateAssigned));
      }
      return processedDateAssigned;
    },
    async onToolbarItemSelected(): Promise<void> {
      await downloadClaimsDocument({
        _ids: [this.lossFormData._id],
        policyPrefix: this.lossFormData.companyPrefix
      });
    }
  },
  computed: {
    ...claimsMapState(["editing", "makingApiRequest"]),
    ...authMapGetters(["getCurrentUser"]),
    claimId(): string {
      return this.$route.params._id;
    },
    getData(): any {
      let results = {};
      results = {
        sections: [
          {
            sectionTitle: "Location Address",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "4",
            information: [
              {
                informationColumnSize: 4,
                key: "",
                isArray: false,
                value: `${get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.LocalAddress",
                  ""
                )}, ${get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.City",
                  ""
                )},
                ${get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.State",
                  ""
                )}, ${get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.ZipCode",
                  ""
                )}`
              }
            ]
          },
          {
            sectionTitle: "Loss Information",
            showSection: true,
            showDivider: true,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: 2,
                key: "Date of Loss",
                isArray: false,
                value: this.getProcessedDate(
                  "data.lossFormOtherInfo.FormInfo.LossDate"
                )
              },
              {
                informationColumnSize: 2,
                key: "IF 'OTHER'",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.Other",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.Other",
                  ""
                )
              },
              {
                informationColumnSize: 2,
                key: "Police or Fire Dept. Reported to",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.ReportTo",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.ReportTo",
                  ""
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: 2,
                key: "Kind of Loss",
                isArray: false,
                value: selectOptions.getKindOfLoss(
                  get(
                    this.editing,
                    "data.lossFormOtherInfo.FormInfo.ClassId",
                    ""
                  )
                )
              },
              {
                informationColumnSize: 2,
                key: "Description of Loss",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.Descripton",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.Descripton",
                  ""
                )
              },
              {
                informationColumnSize: 2,
                key: "Form Type",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Coverages.FormType",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.Coverages.FormType",
                  ""
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: 2,
                key: "Claimant Name",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.ClaimAntName",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.ClaimAntName",
                  ""
                )
              },
              {
                informationColumnSize: 2,
                key: "Claimant Phone Number",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.ClaimAntPhone",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.ClaimAntPhone",
                  ""
                )
              },
              {
                informationColumnSize: 2,
                key: "Claimant Address",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.ClaimAntAddress",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.FormInfo.ClaimAntAddress",
                  ""
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: 2,
                key: "Phone no.",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Client.Phone",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.Client.Phone",
                  ""
                )
              },
              {
                informationColumnSize: 2,
                key: "Alternate Phone no.",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Client.BusinessPhone",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.Client.BusinessPhone",
                  ""
                )
              },
              {
                informationColumnSize: 2,
                key: "Email Address",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Client.OtherAuthConcact",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.Client.OtherAuthConcact",
                  ""
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: 6,
                key: "Additional Remarks",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Client.NotesComments",
                  ""
                ),
                value: get(
                  this.editing,
                  "data.lossFormOtherInfo.Client.NotesComments",
                  ""
                )
              }
            ]
          },
          {
            sectionTitle: "Loss Form Questions",
            showSection: true,
            showDivider: true,
            sectionColumnSize: "4",
            information: [
              {
                informationColumnSize: 4,
                key: "Has mortgage been verified with insured?",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Info.MortgageVerifiedQtn",
                  ""
                ),
                value: this.getYesOrNo(
                  get(
                    this.editing,
                    "data.lossFormOtherInfo.Info.MortgageVerifiedQtn",
                    ""
                  )
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "4",
            information: [
              {
                informationColumnSize: 4,
                key:
                  "Has the risk been impacted by flood, surface water or tidal surge?",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Coverages.FloodWaterRisk",
                  ""
                ),
                value: this.getYesOrNo(
                  get(
                    this.editing,
                    "data.lossFormOtherInfo.Coverages.FloodWaterRisk",
                    ""
                  )
                )
              }
            ]
          },
          // {
          //   sectionTitle: "",
          //   showSection: true,
          //   showDivider: false,
          //   sectionColumnSize: "4",
          //   information: [
          //     {
          //       informationColumnSize: 4,
          //       key:
          //         "If a settlement check is offered how would you like to receive the payment?",
          //       isArray: false,
          //       showColumn: get(
          //         this.editing,
          //         "data.lossFormOtherInfo.FormInfo.paymentOption",
          //         ""
          //       ),
          //       value: this.getPaymentOption(
          //         get(
          //           this.editing,
          //           "data.lossFormOtherInfo.FormInfo.paymentOption",
          //           ""
          //         )
          //       )
          //     }
          //   ]
          // },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: 2,
                key: "Date Assigned",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Coverages.DateAssigned",
                  ""
                ),
                value: this.getProcessedDate(
                  "data.lossFormOtherInfo.Coverages.DateAssigned"
                )
              },
              {
                informationColumnSize: 2,
                key: "Field Adjuster",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Coverages.FieldAssigned",
                  ""
                ),
                value: this.getFieldAdjuster(
                  get(
                    this.editing,
                    "data.lossFormOtherInfo.Coverages.FieldAssigned",
                    ""
                  )
                )
              },
              {
                informationColumnSize: 2,
                key: "Claims Representative",
                isArray: false,
                showColumn: get(
                  this.editing,
                  "data.lossFormOtherInfo.Coverages.ClaimRepresentative",
                  ""
                ),
                value: get(this.editing, "data.isLegacy", false)
                  ? this.getAtlasClaimRepresentative(
                      get(
                        this.editing,
                        "data.lossFormOtherInfo.Coverages.ClaimRepresentative",
                        ""
                      )
                    )
                  : get(
                      this.editing,
                      "data.lossFormOtherInfo.Coverages.ClaimRepresentative",
                      ""
                    )
              }
            ]
          }
        ]
      };
      return results;
    },
    queryOverride(): any {
      return {
        "data.claimsRequestId": this.editing?._id,
        activityType: "ClaimsRequestActivity"
      };
    }
  }
});
