
import Vue from "vue";
export default Vue.extend({
  name: "DisplayData",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
});
