import { get } from "lodash";

export function processKindOfLoss(KindOfLossOptions: object): any {
  return Object.entries(KindOfLossOptions).map((entry: any) => {
    return {
      value: entry[0],
      label: `${entry[1]}`
    };
  });
}

export function getLossMortgageAddress(LossData: object): any {
  const title = get(LossData, "lossFormOtherInfo.Info.Mortgage", "") || "";
  const mortgageAddress =
    get(LossData, "lossFormOtherInfo.Info.MortgageAddress", "") || "";
  const mortgageCity =
    get(LossData, "lossFormOtherInfo.Info.MortgageCity", "") || "";

  const mortgageState =
    get(LossData, "lossFormOtherInfo.Info.MortgageStateProvCd", "") || "";

  const mortgagePostalCode =
    get(LossData, "lossFormOtherInfo.Info.MortgagePostalCode", "") || "";

  return `${title}\n${mortgageAddress} ${mortgageCity} ${mortgageState} ${mortgagePostalCode}`;
}
